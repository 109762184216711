import React, { useState } from "react";

import axios from "axios";
import readXlsxFile from "read-excel-file";
import exportFromJSON from "export-from-json";

import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import { Header, UploadFileContainer } from "./Modal.style";
import { ButtonWrapper, Button } from "../Dashboard/Dashboard.style";

import { useAuth } from "../../Auth/Auth";

const props = [
  "Subject",
  "Subject-Thumbnail",
  "Chatper",
  "Chapter-Thumbnail",
  "Topic",
  "Topic-Thumbnail",
  "Module",
  "Module-Thumbnail",
];
function unwrapDoubltQuote(row) {
  for (let prop of props) {
    if (row[prop]) row[prop] = row[prop].replace(/"/g, "");
  }
  return row;
}

function cleanString(value) {
  return value.replace(/^"+|"+$/g, "").replace(/\\"/g, '"');
}

function cleanObject(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === "string" ? cleanString(value) : value,
    ])
  );
}

function parseCSVLine(line) {
  return line.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g)?.map(field =>
      field.replace(/^"|"$/g, '') // Remove outer quotes
  ) || [];
}

function ImportModal({ setModal, sources }) {
  const { readCookie, DisplaySnackbar } = useAuth();

  const [option, setOption] = useState(null);
  const [fileName, setFileName] = useState("");
  const [contents, setContents] = useState({
    modules: [],
    moduleIds: [],
  });

  const fileUploadHandler = async (e) => {
    let files = e.target.files[0];

    const nameList = files.name.split(".");
    const type = nameList[nameList.length - 1];
    var contents = [],
      mIds = [];
    if (type === "xlsx") {
      await readXlsxFile(files).then(async (rows) => {
        let headers = rows[0];
        for (let index = 1; index < rows.length; index++) {
          const row = rows[index];
          console.log(row);
          let newObj = {};
          for (let i in headers) {
            newObj[headers[i]] = row[i];
            if (headers[i] === "ModuleId") {
              mIds.push(row[i]);
            }
          }
          contents.push(unwrapDoubltQuote(newObj));
        }
        setFileName(files.name);
        setContents({
          modules: contents,
          moduleIds: mIds,
        });
      });
    } else if (type === "csv") {
      const reader = new FileReader();
      reader.readAsText(files);
      reader.onload = async (e) => {
        const rows = e.target.result
        .split("\n")
        .map(row => row.replace(/\r/g, ""))
        .filter(row => row.trim() !== ""); // Remove empty rows
        let headers = parseCSVLine(rows[0]);
    
        const data = rows.slice(1).map(row => {
            let values = parseCSVLine(row);
            let obj = Object.fromEntries(headers.map((key, i) => [key, values[i] || ""]));
            mIds.push(obj.ModuleId && obj.ModuleId?.replace("\r", ""));
            return cleanObject(obj);
        });
        setFileName(files.name)
        setContents({
          modules: data,
          moduleIds: mIds,
        });
      };
    } else {
      DisplaySnackbar("Upload .csv or xlsx sheet", "error");
      setFileName("");
    }
    
  };

  const handleSubmit = async () => {
    if (option === null) {
      DisplaySnackbar("Please choose path to import content", "error");
      return;
    }

    const source = sources[option];
    if (!source) {
      DisplaySnackbar(
        "Something went wrong. Refresh page and try again",
        "error"
      );
      return;
    }

    if (!contents.modules.length) {
      DisplaySnackbar("Cannot import empty file", "error");
      return;
    }

    console.log(contents.modules);
    console.log(contents.moduleIds);

    DisplaySnackbar("Please wait importing data takes time", "info");

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/import${source.api}`,
      { contents },
      {
        headers: {
          token: `${readCookie("token")}`,
        },
        validateStatus: () => true,
      }
    );

    if (res.data.status !== "success") {
      DisplaySnackbar(res.data.message, "error");
      return;
    }

    const date = new Date();

    exportFromJSON({
      data: `
IMPORT STATUS: ${new Date()}

Subjects : ${res.data.data.subjects || 0}
Chapters : ${res.data.data.chapters || 0}
Topics   : ${res.data.data.topics || 0}
Modules  : ${res.data.data.modules || 0}

Error: ${res.data?.errors?.length || 0}

${
  res.data?.errors?.length
    ? `For Error Details, check downloaded file ERROR_LOGS_${date.toLocaleDateString()}.csv`
    : ""
}
      `,
      fileName: `STATUS_LOG_${date.toLocaleDateString()}`,
      exportType: exportFromJSON.types.txt,
    });

    if (res.data?.errors?.length) {
      exportFromJSON({
        data: res.data?.errors || [],
        fileName: `ERROR_LOGS_${date.toLocaleDateString()}`,
        exportType: exportFromJSON.types.csv,
      });
    }

    setContents([]);
    setModal(false);
    DisplaySnackbar("Refresh page to see the changes", "info");
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <Header>
          <h2>Import</h2>
          <h2 onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <p>Choose path to import contents</p>
        <div style={{ margin: "10px 0", display: "flex", maxWidth: "500px" }}>
          <input
            type="radio"
            name="import-content"
            onChange={() => setOption(0)}
            style={{ display: "block", margin: "auto 20px auto 0" }}
          />
          <label style={{ fontSize: "1.1rem" }}>
            Path <b>{sources[0].path}</b>
          </label>
        </div>
        {sources[1].name && (
          <div style={{ margin: "10px 0", display: "flex", maxWidth: "500px" }}>
            <input
              type="radio"
              name="import-content"
              onChange={() => setOption(1)}
              style={{ display: "block", margin: "auto 20px auto 0" }}
            />
            <label style={{ fontSize: "1.1rem" }}>
              Path{" "}
              <b>
                {sources[1].path} {">"} {sources[1].name}
              </b>
            </label>
          </div>
        )}
        <UploadFileContainer>
          <div style={{ margin: "0" }}>
            <label for="uploadFile">
              <span className="firstLabel file">FILE</span>
              <span className="secondLabel file">
                {`${fileName ? fileName : "NO SELECTED FILE"}`}{" "}
              </span>
            </label>
            <input
              type="file"
              id="uploadFile"
              onChange={(e) => fileUploadHandler(e)}
              style={{ display: "none" }}
            />
          </div>
          <p className="text">
            Upload <span style={{ color: "#5CE0D2" }}>xlsx</span> or{" "}
            <span style={{ color: "#5CE0D2" }}>csv</span> file
          </p>
        </UploadFileContainer>
        <ButtonWrapper>
          <Button onClick={() => setModal(false)}>CANCEL</Button>
          <Button onClick={handleSubmit}>IMPORT</Button>
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default ImportModal;
