import styled from "styled-components";

export const EditContainer=styled.div`

min-height:296px;


max-width:1010px;
margin:15px auto;
border:2px dashed #0f93a8;
border-radius:9px;
padding:10px 15px;
display:flex;


`

export const EditContainerDiv1=styled.div`

display:flex;
flex-direction:column;
justify-content:space-between;
flex:0.6;
.optionsChild{
    display:flex;
    flex:1;
    align-items:center;
    margin:10px 0;

}

.buttonWrapper{
    display:flex;

}

`
export const EditContainerDiv2=styled.div`

flex:0.4;

`
export const Button=styled.div`
background-color: rgba(14,154,171,.14)!important;
    border-radius: 16px!important;
    color: #0e9aab!important;
    padding: 8px 25px!important;
    font-size: 16px!important;
    font-weight: 600!important;
    margin-bottom: 20px;
    margin-right:10px;
    cursor:pointer;
`
export const InputField=styled.input`
display:block;

width:100%;

border:1px dashed grey;
height:38px;
padding:5px 10px;
box-sizing:border-box;

border-radius:10px;

`