import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0px;
  }
`;

export const Container = styled.div``;
export const UploadAssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  .icon {
    margin-right: 10px;
  }
`;

export const UploadAssessmentButton = styled.div`
  color: #fff;
  background-color: #5ce0d2;
  display: flex;
  align-items: center;
  padding: 7px 17px;
  font-size: 1.1rem;
  outline: none;
  border: none;
  border-radius: 15px;
  .icon {
    font-size: 1.2rem;
  }
`;

export const UploadFileContainer = styled.div`
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  margin-top: 30px;

  .firstLabel {
    padding: 8px 10px;
    background-color: #5ce0d2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .secondLabel {
    padding: 8px 14px;
    border: 1px dashed #5ce0d2;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .text {
    margin-top: 10px;
    color: grey;
  }
`;
export const UploadAssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 70%;
  height: 170px;
  margin: 0 auto;
  margin-top: 30px;

  .firstLabel {
    padding: 8px 10px;
    background-color: #5ce0d2;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .secondLabel {
    padding: 8px 14px;
    border: 1px dashed #5ce0d2;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .text {
    font-size: 1.3rem;
  }
`;
export const AssesmentButton = styled.button`
  border: none;
  background-color: #5ce0d2;
  border-radius: 15px;
  padding: 11px 50px;
  color: #fff;
  cursor: pointer;
`;
export const UploadAssessMentButtonWrapper = styled.div`
  display: flex;

  justify-content: space-between;
`;
export const CreateAssessmentContainer = styled.div`
`;
export const AssessmentContainer = styled.div`
  border: 1.5px dashed #5ce0d2;
  border-radius: 10px;
  padding: 15px;
  .button2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const AssessmentContent = styled.div`
  display: flex;
  justify-content: space-between;
  .firstClass {
    flex: 0.7;
  }

  .input {
    position: relative;
  }
  .textInput {
    display: block;
    width: 100%;
    height: auto;
    border: 1px solid grey;
    border-radius: 8px;
    padding: 8px;
    background-color: transparent;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  .icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .answerIcon {
    left: 95%;
    top: 90%;
  }
  .secondClass {
    flex: 0.25;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    // justify-content:space-between;
  }
`;

export const Wrapper = styled.div`
  display: ${({ display }) => (display ? "block" : "none")};

  // ${({ display }) => console.log(display)}

  z-index: 100000;
  position: absolute;

  background-color: #fff;
  right: 0;
  left: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 3px grey;
  border-radius: 5px;
  margin-top: 10px;
`;
export const WrapperDiv = styled.div`
  // z-index:-34;

  .dropdown {
    background-color: transparent !important;
    color: #fff !important;
  }
  .dropdown button {
    background-color: transparent;
    width: 100%;
    border: 1px solid grey;
    color: black;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .dropdown button:hover {
    border: 1px solid grey !important;
    color: black !important;
  }
  .dropdown button:focus {
    border: 1px solid grey !important;
    color: black !important;
  }
  a:focus {
    background-color: #fff !important;
  }
  .dropdown button:active {
    border: none !important;
  }
  .dropdown-menu {
    width: 100% !important;
  }
  .btn-primary:active,
  .active {
    background-color: #fff !important;
  }
`;
export const Option = styled.div`
  min-width: 80%;
  padding: 6px 10px;
  border-radius: 10px;
  border: 1px solid #e8e9eb;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .fill {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    // border-color:#green;
    border: 2px solid #5ce0d2;
  }
`;
export const Dropdown = styled.div`
  position: relative;
  margin-bottom: 10px;
  .select {
    position: absolute;

    border: none;
    border-color: transparent;
    top: 0;
    right: 0;
    height: 10px;
    width: 10px;
  }
  max-height: 36px;
`;
export const AssessmentButton = styled.button`
  border: none;
  background-color: #5ce0d2;
  border-radius: 15px;
  padding: 11px 50px;
  color: #fff;
  cursor: pointer;
`;
export const AddModulesContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 20px 0px;
`;
