import React, { useState, useEffect, useRef } from "react";

import "./MathEditor.css";

const mathSymbols = [
  { name: "Fraction", symbol: "\\frac{a}{b}" },
  { name: "Exponent", symbol: "a^b" },
  { name: "Square Root", symbol: "\\sqrt{x}" },
  { name: "Cube Root", symbol: "\\sqrt[3]{x}" },
  { name: "nth Root", symbol: "\\sqrt[n]{x}" },
  { name: "π", symbol: "\\pi" },
  { name: "θ", symbol: "\\theta" },
  { name: "∑", symbol: "\\sum_{n=1}^{N} a_n" },
  { name: "∫", symbol: "\\int_{a}^{b} f(x)dx" },
  { name: "∬", symbol: "\\iint_{D} f(x,y)dxdy" },
  { name: "∭", symbol: "\\iiint_{V} f(x,y,z)dxdydz" },
  { name: "α", symbol: "\\alpha" },
  { name: "β", symbol: "\\beta" },
  { name: "γ", symbol: "\\gamma" },
  { name: "δ", symbol: "\\delta" },
  { name: "ε", symbol: "\\epsilon" },
  { name: "λ", symbol: "\\lambda" },
  { name: "μ", symbol: "\\mu" },
  { name: "σ", symbol: "\\sigma" },
  { name: "ω", symbol: "\\omega" },
  { name: "log", symbol: "\\log" },
  { name: "ln", symbol: "\\ln" },
  { name: "cos", symbol: "\\cos" },
  { name: "sin", symbol: "\\sin" },
  { name: "tan", symbol: "\\tan" },
  { name: "lim", symbol: "\\lim_{x \\to a} f(x)" },
  { name: "→", symbol: "\\rightarrow" },
  { name: "←", symbol: "\\leftarrow" },
  { name: "≥", symbol: "\\geq" },
  { name: "≤", symbol: "\\leq" },
  { name: "≠", symbol: "\\neq" },
  { name: "≈", symbol: "\\approx" },
  { name: "×", symbol: "\\times" },
  { name: "÷", symbol: "\\div" },
  { name: "±", symbol: "\\pm" },
  { name: "∞", symbol: "\\infty" },
  { name: "Factorial", symbol: "n!" },
  { name: "Binomial Coefficient", symbol: "\\binom{n}{k}" },
  { name: "Vector", symbol: "\\vec{v}" },
  { name: "Dot Product", symbol: "\\mathbf{a} \\cdot \\mathbf{b}" },
  { name: "Cross Product", symbol: "\\mathbf{a} \\times \\mathbf{b}" },
  { name: "Derivative", symbol: "\\frac{d}{dx} f(x)" },
  { name: "Partial Derivative", symbol: "\\frac{\\partial f}{\\partial x}" },
  { name: "Second Derivative", symbol: "\\frac{d^2}{dx^2} f(x)" },
  { name: "Summation", symbol: "\\sum_{i=1}^{n} a_i" },
  {
    name: "Matrix 2×2",
    symbol: "\\begin{bmatrix}a & b \\\\ c & d\\end{bmatrix}",
  },
  {
    name: "Matrix 3×3",
    symbol:
      "\\begin{bmatrix}a & b & c \\\\ d & e & f \\\\ g & h & i\\end{bmatrix}",
  },
  {
    name: "Quadratic Formula",
    symbol: "x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}",
  },
  { name: "Pythagorean Theorem", symbol: "a^2 + b^2 = c^2" },
  { name: "Euler's Formula", symbol: "e^{ix} = \\cos{x} + i\\sin{x}" },
  {
    name: "Taylor Series",
    symbol: "f(x) = \\sum_{n=0}^{\\infty} \\frac{f^{(n)}(a)}{n!} (x-a)^n",
  },
  {
    name: "Fourier Series",
    symbol:
      "f(x) = a_0 + \\sum_{n=1}^{\\infty} \\left( a_n \\cos{nx} + b_n \\sin{nx} \\right)",
  },
];

const MathEditor = ({ saveEditor, closeEditor, initialHtml = "" }) => {
  const [mathInput, setMathInput] = useState(initialHtml);
  const inputRef = useRef(null);
  const [mathPreview, setMathPreview] = useState(initialHtml);

  function renderLatex() {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }

  useEffect(() => {
    renderLatex();
  }, [mathPreview]);

  const insertSymbol = (symbol) => {
    setMathInput((prev) => {
      const textarea = inputRef.current; //document.getElementById("mathInput");
      if (!textarea) return prev; // Ensure the textarea exists

      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const updatedText =
        prev.substring(0, startPos) + symbol + prev.substring(endPos);
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(
          startPos + symbol.length,
          startPos + symbol.length
        );
      }, 0);

      return updatedText;
    });
    renderLatex();
  };

  const updateMath = (e) => {
    setMathInput(e.target.value);
    setMathPreview(e.target.value.replace(/\n/g, ""));
  };

  useEffect(() => {
    return () => {
      setMathInput("");
      setMathPreview("");
    };
  }, []);

  return (
    <div className="math-editor">
      <div className="math-editor-modal">
        <div className="input-container">
          <textarea
            ref={inputRef}
            value={mathInput}
            onChange={updateMath}
            placeholder="Enter text and LaTeX math here..."
          ></textarea>
          <div
            className="preview"
            dangerouslySetInnerHTML={{ __html: `${mathPreview}` }}
          ></div>
        </div>
        <br />
        <div className="toolbar">
          <button
            onClick={() => {
              insertSymbol("$$ insert formula here $$");
            }}
          >
            Insert Formula
          </button>
          {mathSymbols.map((sym, index) => (
            <button key={index} onClick={() => insertSymbol(sym.symbol)}>
              {sym.name}
            </button>
          ))}
        </div>

        <br />
        <div className="buttons">
          <button onClick={closeEditor}>Close</button>
          <button onClick={() => saveEditor(inputRef.current.innerHTML)}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default MathEditor;
