import React, { useEffect, useMemo, useState } from "react";
import {
  QuestionWrapper,
  QuestionTypeChild,
  QuestionType,
  QuestionText,
  QuestionHeader,
  QuestionFooter,
  QuestionHelper,
  ImgContent,
} from "./Questions.style";
import EditQuestion from "./EditQuestion/Editquestion";
import QuestionOptions from "./QuestionOptions";
import EditAssessMent from "../../images/editAssessment.svg";
import Logo from "../../images/2xcell_logo.png";
import Delete from "../../images/deleteAssessment.svg";

import { htmlDecode } from "../../utils/parse";

const getText = (data) => {
  if (data === "mcq") return "Select One Option";
  else if (data === "mmcq") return "Select Multiple Correct Options";
  else if (data === "oneword") return "Answer in one word";
  else if (data === "fillup") return "Fill in the blanks";
  else if (data === "correctstatement") return "True or False";
};

function Questions(props) {
  const [edit, setEdit] = useState(false);
  const [dimension, setDimension] = useState({
    width: "100%",
    height: "100%",
    loaded: false,
  });

  useEffect(() => {
    if (!props.images?.questionImg) return;
    const img = new Image();
    img.src = props?.images?.questionImg;
    img.onload = () => {
      if (img.width > 400 || img.height > 400) {
        dimension.width = "75%";
        dimension.height = "75%";
      }
      dimension.loaded = true;
      setDimension({ ...dimension });
    };
  }, [props.images?.questionImg]);

  const QuestionImage = useMemo(() => {
    if (!dimension.loaded) return <></>;
    return (
      <ImgContent maxWidth={dimension.width} maxHeight={dimension.height}>
        <img src={props.images.questionImg} alt="questionImg" />
      </ImgContent>
    );
  }, [dimension]);

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, [props,edit]);

  return (
    <>
      {edit ? (
        <EditQuestion
          handleUpdate={props?.handleUpdateQuestion}
          setEdit={setEdit}
          {...props}
        />
      ) : (
        <QuestionWrapper>
          <QuestionHeader>
            <QuestionText>
              {`Q${props?.no}. `}
              <span
                dangerouslySetInnerHTML={{
                  __html: htmlDecode(props?.question || ""),
                }}
              />
              {/* {props?.question} */}
              {/* </span> */}
            </QuestionText>
            <QuestionType>
              <QuestionTypeChild>
                {props?.difficulty.charAt(0).toUpperCase() +
                  props?.difficulty.slice(1)}
              </QuestionTypeChild>
              <QuestionTypeChild>
                {props?.cognitive.charAt(0).toUpperCase() +
                  props?.cognitive.slice(1)}
              </QuestionTypeChild>
            </QuestionType>
          </QuestionHeader>
          <div style={{ marginTop: "10px" }}>
            <div className="questionContainer">
              {props.images?.questionImg && QuestionImage}
              <QuestionOptions
                isQuestionImg={props.images?.questionImg}
                questionType={props?.questionType}
                options={props?.options}
                marks={props?.marks}
                question={props?.question}
                visibility={props?.visibility}
                images={props?.images}
                difficulty={props?.difficulty}
                answer={props?.correctAnswers[props?.questionType]}
              />
            </div>
          </div>
          <QuestionFooter>
            <div className="firstDiv">
              <img src={Logo} alt="logo" />
            </div>
            <div className="icons">
              <img
                onClick={() => setEdit(true)}
                style={{ cursor: "pointer" }}
                src={EditAssessMent}
                alt="edit"
              />
              <img
                src={Delete}
                style={{ cursor: "pointer" }}
                onClick={() => props.handleDeleteQuestion(props.id)}
                alt="delete"
              />
            </div>
          </QuestionFooter>
          <QuestionHelper>{getText(props.questionType)}</QuestionHelper>
        </QuestionWrapper>
      )}
    </>
  );
}

export default Questions;
