import { useEffect, useState } from "react";

import { InputField } from "./Editquestion.style";
import MathEditor from "../MathEditor/MathEditor";

import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import TrashOultinedIcon from "@material-ui/icons/CloseTwoTone";

import FormulaIcon from "../../../images/formula.png";

function ImageField(props) {
  return (
    <>
      {props.isImage ? (
        <div
          style={{ margin: "auto 10px auto 5px" }}
          onClick={props.removeImage}
        >
          <TrashOultinedIcon />
        </div>
      ) : (
        <label
          style={{
            fontSize: "2rem",
            cursor: "pointer",
            margin: "auto 10px auto 5px",
          }}
          for={props.id}
        >
          <AttachFileOutlinedIcon />
        </label>
      )}
      {!props.isImage ? (
        <>
          <input
            onChange={props.addImage}
            id={props.id}
            style={{ display: "none" }}
            type="file"
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default function EditQuestionInput({
  id,
  value,
  placeholder,
  onChange,
  isImage,
  imageName,
  addImage,
  removeImage,
}) {
  const [openEditor, setOpenEditor] = useState(false);

  function save(content) {
    onChange(content);
    setOpenEditor(false);
  }

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, [value]);

  return (
    <div style={{width:"100%"}}>
      <div className="optionsChild">
        <InputField
          onChange={(e) => onChange(e.target.value)}
          value={value}
          placeholder={placeholder}
        />
        <ImageField
          id={id}
          isImage={isImage}
          filename={imageName}
          addImage={addImage}
          removeImage={removeImage}
        />
        {openEditor && (
          <MathEditor
            closeEditor={() => setOpenEditor(false)}
            saveEditor={save}
            initialHtml={value}
          />
        )}
        <img
          src={FormulaIcon}
          style={{
            width: "20px",
            margin: "auto 10px auto 5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenEditor(true);
          }}
        />
      </div>
      <span>{imageName}</span>
    </div>
  );
}
