import { AttachFileOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import MathEditor from "../MathEditor/MathEditor";

import FormulaIcon from "../../../images/formula.png";

export default function QuestionInput({
  placeholder,
  name,
  value,
  image,
  disabled,
  handleInput,
  handleFileUpload,
  styles,
}) {
  const [openEditor, setOpenEditor] = useState(false);

  function save(content) {
    handleInput(content);
    setOpenEditor(false);
  }

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, [value]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        opacity: disabled ? "0.5" : "1",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <div style={{ flex: 1 }}>
        <div
          className="input"
          style={{
            flex: 1,
            ...styles,
          }}
        >
          <textarea
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => handleInput(e.target.value)}
            className="textInput"
            style={{
              height: "auto !important",
            }}
          />
          <div className="icon" style={{display:disabled?"none":"block"}}>
            <label style={{ display: "flex" }} htmlFor={name}>
              <AttachFileOutlined />
            </label>
            <input
              id={name}
              style={{ display: "none" }}
              type="file"
              accept="images/*"
              onChange={handleFileUpload}
            />
          </div>
        </div>
        {image || ""}
      </div>
      {openEditor && (
        <MathEditor
          closeEditor={() => setOpenEditor(false)}
          saveEditor={save}
          initialHtml={value}
        />
      )}
      <img
        src={FormulaIcon}
        style={{
          width: "20px",
          margin: "auto 10px auto 5px",
          cursor: "pointer",
        }}
        onClick={() => {
          if(disabled) return;
          setOpenEditor(true)
        }}
      />
    </div>
  );
}
